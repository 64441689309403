import React from "react";
import Date from "../date/Date";

export default (props) => (
    <div className="post-header">
        <div className="container post-header-intro">
            <div className="row">
                <div className="col-lg-12">
                    <Date date={props.date} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <h3> { props.title } </h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <hr />
                </div>
            </div>
        </div>
    </div>
);