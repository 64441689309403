import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PostHeader from '../components/post-header/PostHeader'
import PortfolioBanner from '../components/portfolio-banner/PortfolioBanner'
import Sections from '../components/sections/Sections'
import SEO from '../components/seo'

class PostTemplate extends Component {
  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-120px'
  }

  componentDidMount() {
    this.setFooterMargin()
  }

  render() {
    const currentPage = this.props.data.wordpressPost

    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.featured_media} />
        <div className="take-up">
          <PostHeader title={currentPage.title} date={currentPage.date} />
          <div
            className="excerpt"
            dangerouslySetInnerHTML={{ __html: currentPage.excerpt }}
          />
          <Sections data={currentPage.acf.sections_post} />
        </div>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
              srcSet
            }
          }
        }
      }
      acf {
        sections_post {
          ... on WordPressAcf_gallery_function {
            gallery {
              alt_text
              localFile {
                childImageSharp {
                  small: fixed(width: 490, height: 506, cropFocus: CENTER) {
                    src
                  }
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_editor_function {
            text
          }
          ... on WordPressAcf_text_with_photo_function {
            text_with_photo {
              text
              photo {
                alt_text
                localFile {
                  childImageSharp {
                    fixed(width: 750, height: 422) {
                      src
                    }
                  }
                }
              }
            }
          }
        }
      }
      date(formatString: "MM / DD / YYYY")
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
