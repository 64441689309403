import React from "react";
import Img from 'gatsby-image';

export default (props) => (
    props.data.map(item => (
        <div className="container">
            <div className="text-with-photo">
                <div className="text-column" dangerouslySetInnerHTML={ { __html: item.text } }></div>    
                <div className="image-column">
                    { item.photo &&
                        <Img fixed={ item.photo.localFile.childImageSharp.fixed } alt={ item.photo.alt_text  } />
                    }
                </div>
            </div>
        </div>
    ))
);