import React, { Component } from "react";
import Editor from './sections-components/Editor';
import TextWithPhoto from './sections-components/TextWithPhoto';
import Gallery from './sections-components/Gallery';

export default class Sections extends Component {

    render() {
        const RenderSections = (props) => {
            if ( !props.data ) return null;
           return props.data.map(element => {
                let section = element.__typename; 

                switch (section) {
                    case 'WordPressAcf_editor_function':
                        return <Editor data={ element.text } />

                    case 'WordPressAcf_text_with_photo_function':
                        return <TextWithPhoto data={ element.text_with_photo }/>
                    
                    case 'WordPressAcf_gallery_function':
                        return <Gallery data={ element.gallery } />
                   
                    default:
                        return null;
                }
                
            });
        }

        return (
            <div className="post-text">
                <RenderSections data={ this.props.data }/>
            </div>
        );
    }
}