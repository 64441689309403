import React, { Component } from "react";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

import Img from 'gatsby-image';

export default class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            isOpen: false,
            images: [],
        }
    }
    pushImagesToState(){
        this.props.data.map(item => {
            let image = item.localFile.childImageSharp.original.src;
            this.setState(prevState => ({
                images: [...prevState.images, image]
            }));
        });
    }

    componentDidMount() {
        this.pushImagesToState();
    }

    render() {
        const Gallery = (props) => {
            return props.images.map((item, index) => (
                <li onClick={ () => this.setState({ isOpen: true, photoIndex: index }) }>{ <Img fixed={ item.localFile.childImageSharp.small } alt={ item.alt_text }  /> }</li>
            ));
        }

        return (
            <div className="container">
                <div className="gallery">
                    <ul>
                        { this.props.data &&
                            <Gallery images={this.props.data} />
                        }
                    </ul>
                    {this.state.isOpen && (
                    <Lightbox
                    mainSrc={this.state.images[this.state.photoIndex]}
                    nextSrc={this.state.images[(this.state.photoIndex + 1) % this.state.images.length]}
                    prevSrc={this.state.images[(this.state.photoIndex + this.state.images.length - 1) % this.state.images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                        this.setState({
                        photoIndex: (this.state.photoIndex + this.state.images.length - 1) % this.state.images.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                        photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
                        })
                    }
                    />
                    )}
                </div>
            </div>
            
        );
    }
}


